<template>
  <b-card no-body class="card-browser-states">
    <b-card-header>
      <div>
        <b-card-title>Stand da completare</b-card-title>
      </div>
    </b-card-header>

    <!-- body -->
    <b-card-body>
      <div
        v-for="(stand, index) in stands_to_complete"
        :key="index"
        class="d-flex"
      >
        <template v-if="index <= 8">
          <b-media no-body>
            <b-img
              :src="stand.logo_url"
              alt="browser img"
              class="mr-1"
              height="30"
            />
            <h6 class="align-self-center my-auto">{{ stand.name }}</h6>
          </b-media>
          <div class="d-flex align-items-center percentage">
            <span class="font-weight-bold text-body-heading mr-1">
              {{ stands_usage[index] }}%
            </span>
            <vue-apex-charts
              type="radialBar"
              height="30"
              width="30"
              :options="chart.options"
              :series="chartData[index].series"
            />
          </div>
        </template>
      </div>
    </b-card-body>
    <!--/ body -->
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BMedia,
  BImg,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
const $trackBgColor = "#e9ecef";

import { getAllStands } from "@api/stands";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BImg,
    VueApexCharts,
  },
  data() {
    return {
      stands_to_complete: [],
      stands_usage: [],
      chartData: [],
      chartClone: {},
      chartColor: [
        $themeColors.primary,
        $themeColors.warning,
        $themeColors.secondary,
        $themeColors.info,
        $themeColors.danger,
      ],
      chartSeries: [],

      chart: {
        series: [65],
        options: {
          grid: {
            show: false,
            padding: {
              left: -15,
              right: -15,
              top: -12,
              bottom: -15,
            },
          },
          colors: [$themeColors.primary],
          plotOptions: {
            radialBar: {
              hollow: {
                size: "22%",
              },
              track: {
                background: $trackBgColor,
              },
              dataLabels: {
                showOn: "always",
                name: {
                  show: false,
                },
                value: {
                  show: false,
                },
              },
            },
          },
          stroke: {
            lineCap: "round",
          },
        },
      },
    };
  },
  created() {
    let $this = this;
    getAllStands()
      .then((data) => {
        for (let i = 0; i < data.length; i++) {
          if (
            data[i].active_media < 3 &&
            data[i].active_pinpoints < data[i].type.pinpoints.length
          ) {
            $this.stands_to_complete.push(data[i]);
            $this.stands_usage.push(
              Math.round(
                ((data[i].active_media + data[i].active_pinpoints) /
                  (3 + data[i].type.pinpoints.length)) *
                  100
              )
            );
          }
        }
        for (let i = 0; i < $this.stands_to_complete.length; i += 1) {
          const chartClone = JSON.parse(JSON.stringify($this.chart));
          chartClone.options.colors[0] = $this.chartColor[i];
          chartClone.series[0] = $this.stands_usage[i];
          $this.chartData.push(chartClone);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style>
.percentage {
  margin-left: auto;
}
</style>
