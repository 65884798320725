<template>
  <div>
    <b-row class="match-height">
      <b-col md="6" lg="6">
        <b-card text-variant="center" class="card card-congratulations">
          <b-img
            :src="require('@/assets/images/elements/decore-left.png')"
            class="congratulations-img-left"
          />
          <b-img
            :src="require('@/assets/images/elements/decore-right.png')"
            class="congratulations-img-right"
          />

          <b-avatar variant="info" size="70" class="shadow mb-2">
            <feather-icon size="28" icon="AwardIcon" />
          </b-avatar>
          <h1 class="mb-1 mt-50 text-white">Benvenuto {{ user.name }}</h1>
          <b-card-text class="m-auto w-75">
            <b
              >Forum Risk Management Revolution 2022 <br />
              21/11/22 - 24/11/22</b
            >
          </b-card-text>
        </b-card>
      </b-col>
      <b-col md="6" lg="3">
        <statistic-card-with-area-chart
          id="visitors-card"
          icon="UserCheckIcon"
          color="info"
          :statistic="statistics.visitors"
          statistic-title="Visitatori unici"
          :chartData="total_visitors"
          class="stats-card hide"
        />
      </b-col>
      <b-col md="6" lg="3">
        <statistic-card-with-area-chart
          id="stands-card"
          icon="UserPlusIcon"
          color="warning"
          :statistic="statistics.stands_visitors"
          statistic-title="Stand Visitati"
          :chartData="visit_stands"
          class="stats-card hide"
        />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col md="6" lg="6">
        <standsToComplete />
      </b-col>
      <b-col md="6" lg="6">
        <fairPercentage />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col md="4" lg="4">
        <nextNotification />
      </b-col>
      <b-col md="4" lg="4">
        <nextConference />
      </b-col>
      <b-col md="4" lg="4">
        <quickMenu />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BCard, BImg, BAvatar, BCardText, BRow, BCol } from "bootstrap-vue";
import StatisticCardWithAreaChart from "@core/components/statistics-cards/StatisticCardWithAreaChart.vue";
import StandsToComplete from "./dashboard/stands-to-complete.vue";
import FairPercentage from "./dashboard/fair-percentage.vue";
import NextNotification from "./dashboard/next-notification.vue";
import NextConference from "./dashboard/next-conference.vue";
import QuickMenu from "./dashboard/quick-menu.vue";
import analytics from "@api/analytics";

export default {
  components: {
    BCard,
    BImg,
    BAvatar,
    BCardText,
    BRow,
    BCol,
    StatisticCardWithAreaChart,
    StandsToComplete,
    FairPercentage,
    NextNotification,
    NextConference,
    QuickMenu,
  },
  data() {
    return {
      user: {},
      statistics: {
        visitors: 0,
        stands_visitors: 0,
      },
      total_visitors: [
        {
          name: "Visite",
          data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
        },
      ],
      visit_stands: [
        {
          name: "Stand",
          data: [10, 41, 35, 51, 49, 62, 69, 91, 23],
        },
      ],
    };
  },
  created() {
    this.getReports();
    this.user = this.getUser();
  },
  mounted() {
    document.getElementById(
      "breadcrumbsPageTitle"
    ).innerHTML = this.$route.meta.pageTitle;
    if (this.user.role == "admin") {
      this.user.admin = true;
    }
  },
  methods: {
    getUser() {
      return this.$store.state.auth.user;
    },
    getReports() {
      let $this = this;
      let visits = [];
      if (analytics.checkBrowser()) {
        analytics.defaultReport().then((data) => {
          for (let i = 0; i < data.length; i++) {
            if (data[i].dimensionValues[0].value == "first_visit") {
              $this.statistics.visitors = data[i].metricValues[0].value;
            } else if (
              data[i].dimensionValues[0].value.includes("visit_stand_")
            ) {
              visits.push(parseInt(data[i].metricValues[0].value));
            }
          }
          $this.statistics.stands_visitors = visits.reduce((a, b) => a + b, 0);
          if ($this.user.admin) {
            document.getElementById("visitors-card").classList.remove("hide");
            document.getElementById("stands-card").classList.remove("hide");
          }
        });
      } else {
        this.$swal({
          title:
            '<span class="font-weight-bolder">' +
            this.$t("alert_title_warning") +
            "</span>",
          icon: "warning",
          text:
            "Statistiche non disponibili, accertarsi di usare un browser tra Chrome, Firefox o Edge.",
          showCancelButton: false,
          focusConfirm: false,
          confirmButtonText: "Ok",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        })
          .then((result) => {})
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
};
</script>

<style scoped>
.hide {
  visibility: hidden;
}
</style>
